import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Profile = ({ user }) => {
  const { t } = useTranslation();
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const docRef = doc(db, "profile", "companyInfo");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompanyInfo(docSnap.data());
      }
    };
    fetchCompanyInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setDoc(doc(db, "profile", "companyInfo"), companyInfo);
  };

  return (
    <Container className="profile-page">
      <h1>{t("profile")}</h1>
      <div className="profile">
        <h2>{user.name}</h2>
        <p>{t("email")}: {user.email}</p>
        <p>{t("joined")}: {user.joinedDate}</p>
      </div>
      <Form onSubmit={handleSubmit}>
        <h2>{t("companyInformation")}</h2>
        <Form.Group as={Row} controlId="formCompanyName">
          <Form.Label column sm="2">
            {t("companyName")}:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="companyName"
              value={companyInfo.companyName}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formEmail">
          <Form.Label column sm="2">
            {t("email")}:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="email"
              name="email"
              value={companyInfo.email}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formPhone">
          <Form.Label column sm="2">
            {t("phone")}:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="phone"
              value={companyInfo.phone}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formAddress">
          <Form.Label column sm="2">
            {t("address")}:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="address"
              value={companyInfo.address}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Button variant="primary" type="submit">
          {t("save")}
        </Button>
      </Form>
    </Container>
  );
};

export default Profile;
