import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from "./pages/Home";
import Inventory from "./pages/Inventory";
import Sales from "./pages/Sales";
import Loans from "./pages/Loans";
import ToolLending from "./pages/ToolLending";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard"; // Import the Dashboard component
import PrivateRoute from "./components/PrivateRoute";
import CustomNavbar from "./components/Navbar"; // Import the new Navbar component
import "./App.css";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import Invoices from "./pages/Invoices";
// import DashboardRecharts from "./pages/DashboardRecharts";

function App() {
  return (
    <Router>
      <CustomNavbar /> {/* Use the new Navbar component */}
      <Container fluid="lg" className="my-4 px-3 px-md-4 px-lg-5">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/inventory"
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          />
          <Route
            path="/sales"
            element={
              <PrivateRoute>
                <Sales />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/loans"
            element={
              <PrivateRoute>
                <Loans />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices"
            element={
              <PrivateRoute>
                <Invoices />
              </PrivateRoute>
            }
          />
          <Route
            path="/tools"
            element={
              <PrivateRoute>
                <ToolLending />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard" // Add Dashboard route
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Auth />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
