import React from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ChartContainer } from "../../pages/DashboardStyles";

const PieChart = ({ pieChartData }) => {
  const { t } = useTranslation();

  return (
    <ChartContainer style={{ width: "50%", height: "50%" }}>
      <h3 className="text-center">{t("profitVsLoansVsPaidback")}</h3>
      <Pie data={pieChartData} />
    </ChartContainer>
  );
};

export default PieChart;
