import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrintableInvoice from "../PrintableInvoice"; // Import the printable invoice component
import ReactDOMServer from "react-dom/server"; // Import ReactDOMServer to render the component to a string

const SaleModal = ({
  show,
  onHide,
  invoiceNumber,
  customerName,
  selectedItems,
  amountPaid,
  saveInvoice,
  paidAmount, // Add paidAmount prop
}) => {
  const { t } = useTranslation();

  // Calculate the total amount
  const totalAmount = selectedItems.reduce(
    (sum, item) => sum + (item.sellCost - item.discount) * item.quantitySold,
    0
  );

  // Calculate the remaining amount
  const remainingAmount = totalAmount - amountPaid;

  // Handle print functionality
  const handlePrint = () => {
    // Ask the user for confirmation before printing
    const shouldPrint = window.confirm("Do you want to print the invoice?");
    if (shouldPrint) {
      // Render the printable invoice component to a string
      const printableInvoiceContent = ReactDOMServer.renderToString(
        <PrintableInvoice
          invoiceNumber={invoiceNumber}
          customerName={customerName}
          selectedItems={selectedItems}
          totalAmount={totalAmount}
          paidAmount={amountPaid} // Pass paidAmount to PrintableInvoice
          remainingAmount={remainingAmount} // Pass remainingAmount to PrintableInvoice
        />
      );

      // Open a new window for printing
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Invoice - ${invoiceNumber}</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
            <style>
              @media print {
                body { visibility: hidden; }
                .printable-invoice { visibility: visible; }
              }
            </style>
          </head>
          <body>
            <div class="printable-invoice">
              ${printableInvoiceContent}
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  // Handle save and print for Paid
  const handlePaid = () => {
    const isLoan = remainingAmount > 0;
    saveInvoice(isLoan);
    handlePrint();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("invoiceDetails")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <p>
          <strong>{t("invoiceNumber")}:</strong> {invoiceNumber}
        </p>
        {/* <p><strong>{t("customerName")}:</strong> {customerName}</p>
        <p><strong>{t("paidAmount")}:</strong> {amountPaid}</p>
        <p><strong>{t("remainingAmount")}:</strong> {totalAmount - amountPaid}</p> */}
        <PrintableInvoice
          invoiceNumber={invoiceNumber}
          customerName={customerName}
          selectedItems={selectedItems}
          totalAmount={totalAmount}
          paidAmount={amountPaid} // Pass paidAmount to PrintableInvoice
          remainingAmount={remainingAmount} // Pass remainingAmount to PrintableInvoice
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("close")}
        </Button>
        <Button variant="success" onClick={handlePaid}>
          {t("paid")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleModal;
