import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import KurdistanFlag from "../kurdistan-flag.png"; // Adjust the path if needed

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    document.documentElement.dir = lng === "ku" || lng === "ar" ? "rtl" : "ltr";
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        🌐 {i18n.language.toUpperCase()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage("en")}>
          🇬🇧 EN
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("ku")}>
          KU
          <img
            src={KurdistanFlag}
            alt="Kurdistan Flag"
            width="20"
            className="me-2"
          />{" "}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("ar")}>
          🇸🇦 AR
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
