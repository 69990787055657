import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { db } from "../firebase";
import { doc, updateDoc, collection, addDoc, Timestamp } from "firebase/firestore";

const LoanModal = ({ show, onHide, loan = [] }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const handlePayBack = async () => {
    let remainingAmount = parseFloat(amount);
    const totalLoanAmount = loan.reduce((acc, loanDoc) => acc + (loanDoc.totalAmount - loanDoc.paidAmount), 0);

    if (remainingAmount > totalLoanAmount) {
      setError(t("paybackAmountExceedsLoan"));
      return;
    }

    const updatedLoans = [];

    for (const loanDoc of loan) {
      const loanRef = doc(db, "sales", loanDoc.id);
      const remainingLoanAmount = loanDoc.totalAmount - loanDoc.paidAmount;

      if (remainingAmount <= 0) break;

      if (remainingLoanAmount >= remainingAmount) {
        await updateDoc(loanRef, {
          paidAmount: loanDoc.paidAmount + remainingAmount,
          isLoan: loanDoc.totalAmount - (loanDoc.paidAmount + remainingAmount) > 0,
        });
        updatedLoans.push({ ...loanDoc, paidAmount: loanDoc.paidAmount + remainingAmount });
        remainingAmount = 0;
      } else {
        await updateDoc(loanRef, {
          paidAmount: loanDoc.totalAmount,
          isLoan: false,
        });
        updatedLoans.push({ ...loanDoc, paidAmount: loanDoc.totalAmount });
        remainingAmount -= remainingLoanAmount;
      }
    }

    // Save the payment details to the paybacks collection
    await addDoc(collection(db, "paybacks"), {
      amount: parseFloat(amount),
      description,
      username: loan[0].username,
      customerName: loan[0].customerName,
      date: Timestamp.now(),
    });

    // Update the parent component's state with the updated loans
    onHide(updatedLoans);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("payBackLoan")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="formAmount">
            <Form.Label>{t("amount")}</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder={t("amount")}
            />
          </Form.Group>
          <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>{t("description")}</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t("description")}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("close")}
        </Button>
        <Button variant="primary" onClick={handlePayBack}>
          {t("payBack")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoanModal;
