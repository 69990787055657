import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFCxAmmHtfs3xGioYKgX4zJXKZZPR_Sr0",
  authDomain: "dil-planning.firebaseapp.com",
  projectId: "dil-planning",
  storageBucket: "dil-planning.firebasestorage.app",
  messagingSenderId: "443231432126",
  appId: "1:443231432126:web:d08970bf8219c40d1d5def",
  measurementId: "G-YMWCM7FQ0B",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
