import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrintableInvoice from "../PrintableInvoice";

const InvoiceModal = ({ show, handleClose, invoice }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(invoice.items);
  }, [invoice]);

  if (!invoice) return null;

  const { invoiceNumber, paidAmount, customerName, totalAmount } = invoice;

  // Calculate the remaining amount
  const remainingAmount = totalAmount - paidAmount;

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("invoiceDetails")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <p>
          <strong>{t("invoiceNumber")}:</strong> {invoiceNumber}
        </p>
        {/* <p><strong>{t("customerName")}:</strong> {customerName}</p>
        <p><strong>{t("paidAmount")}:</strong> {amountPaid}</p>
        <p><strong>{t("remainingAmount")}:</strong> {totalAmount - amountPaid}</p> */}
        <PrintableInvoice
          invoiceNumber={invoiceNumber}
          customerName={customerName}
          selectedItems={items}
          totalAmount={totalAmount}
          paidAmount={paidAmount} // Pass paidAmount to PrintableInvoice
          remainingAmount={remainingAmount} // Pass remainingAmount to PrintableInvoice
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceModal;
