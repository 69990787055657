import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { db } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const Notifications = ({ user }) => {
  const { t } = useTranslation();
  const [lowStockItems, setLowStockItems] = useState([]);
  const [overdueLoans, setOverdueLoans] = useState([]);

  useEffect(() => {
    const unsubscribeItems = onSnapshot(collection(db, "items"), (snapshot) => {
      const items = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lowStock = items.filter((item) => item.quantity < 2);
      setLowStockItems(lowStock);
    });

    const unsubscribeSales = onSnapshot(
      query(collection(db, "sales"), where("isLoan", "==", true)),
      (snapshot) => {
        const sales = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const currentDate = new Date();
        const overdueLoansArray = sales
          .filter((sale) => {
            let loanDate = sale.date?.seconds
              ? new Date(sale.date.seconds * 1000)
              : null;

            if (!loanDate) return false;

            const diffDays = Math.ceil(
              (currentDate - loanDate) / (1000 * 60 * 60 * 24)
            );

            return diffDays > 30; // Only show loans overdue by 30+ days
          })
          .map((sale) => ({
            id: sale.id,
            customerName: sale.customerName,
            totalUnpaid: sale.totalAmount - sale.paidAmount,
            lastLoanDate: new Date(sale.date.seconds * 1000),
            invoiceNumber: sale.invoiceNumber,
            overdueDays: Math.ceil(
              (currentDate - new Date(sale.date.seconds * 1000)) /
                (1000 * 60 * 60 * 24)
            ),
          }));

        setOverdueLoans(overdueLoansArray);
      }
    );

    return () => {
      unsubscribeItems();
      unsubscribeSales();
    };
  }, []);

  // Function to determine the Bootstrap list group class for overdue loans
  const getLoanListClass = (overdueDays) => {
    if (overdueDays >= 90) return "list-group-item list-group-item-danger"; // Red for overdue > 90 days
    if (overdueDays >= 60) return "list-group-item list-group-item-warning"; // Orange for overdue > 60 days
    return "list-group-item list-group-item-info"; // Blue for overdue > 30 days
  };

  // Function to determine the Bootstrap list group class for low stock items
  const getStockListClass = (quantity) => {
    if (quantity <= 1) return "list-group-item list-group-item-danger"; // Red for out of stock
    if (quantity <= 3) return "list-group-item list-group-item-warning"; // Yellow for low stock
    return "list-group-item list-group-item-success"; // Green for sufficient stock
  };

  return (
    <div className="notifications-page container">
      <h1 className="text-center">{t("notifications")}</h1>
      <Row className="mt-4">
        {/* Overdue Loans Column */}
        <Col md={6}>
          <h2 className="text-danger">{t("overdueLoans")}</h2>
          <ListGroup>
            {overdueLoans.length > 0 ? (
              overdueLoans.map((loan) => (
                <ListGroup.Item
                  key={loan.id}
                  className={getLoanListClass(loan.overdueDays)}
                >
                  <strong>{t("customerName")}:</strong> {loan.customerName}
                  <br />
                  <strong>{t("invoiceNumber")}:</strong> {loan.invoiceNumber}
                  <br />
                  <strong>{t("totalUnpaid")}:</strong> $
                  {loan.totalUnpaid.toFixed(2)}
                  <br />
                  <strong>{t("lastLoanDate")}:</strong>{" "}
                  {loan.lastLoanDate.toLocaleDateString()}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>{t("noOverdueLoansMessage")}</ListGroup.Item>
            )}
          </ListGroup>
        </Col>

        {/* Low Stock Items Column */}
        <Col md={6}>
          <h2 className="text-warning">{t("lowStockItems")}</h2>
          <ListGroup>
            {lowStockItems.length > 0 ? (
              lowStockItems.map((item) => (
                <ListGroup.Item
                  key={item.id}
                  className={getStockListClass(item.quantity)}
                >
                  <strong>{t("itemName")}:</strong> {item.itemName}
                  <br />
                  <strong>{t("quantity")}:</strong> {item.quantity}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>{t("noLowStockItemsMessage")}</ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Notifications;
