import React from "react";
import { Modal, Button } from "react-bootstrap";
import ItemForm from "./ItemForm";

const ItemModal = ({ showModal, setShowModal, selectedItem, addItem, updateItem }) => {
  const handleClose = () => setShowModal(false);

  const handleSubmit = (itemData) => {
    if (selectedItem) {
      updateItem({ ...itemData, id: selectedItem.id });
    } else {
      addItem(itemData);
    }
    handleClose();
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedItem ? "Edit Item" : "Add Item"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ItemForm addItem={handleSubmit} item={selectedItem} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModal;