// Inventory.js
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import ItemList from "../components/inventory/ItemList";
import ItemModal from "../components/inventory/ItemModal";
import ImportData from "../components/inventory/ImportData";
import "./Inventory.css"; // Import the CSS file for custom styles

const Inventory = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [user] = useAuthState(auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showItemFormModal, setShowItemFormModal] = useState(false);

  const handleOpenItemFormModal = () => {
    setSelectedItem(null);
    setShowItemFormModal(true);
  };

  useEffect(() => {
    const itemsCollection = collection(db, "items");
    const unsubscribe = onSnapshot(itemsCollection, (querySnapshot) => {
      const itemsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        let dateAdded = data.dateAdded ? new Date(data.dateAdded) : new Date();
        return { id: doc.id, ...data, dateAdded };
      });
      setItems(itemsData);
    });
    return () => unsubscribe();
  }, []);

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency (e.g., "IQD" for Iraqi Dinar)
    }).format(amount);
  };

  const addItem = async (itemData) => {
    try {
      await addDoc(collection(db, "items"), {
        ...itemData,
        dateAdded: new Date().toISOString(),
        username: user.email,
      });
    } catch (err) {
      setError("Failed to add item.");
    }
  };

  const updateItem = async (updatedItem) => {
    try {
      console.log("Updating item:", updatedItem); // Debugging line
      const itemRef = doc(db, "items", updatedItem.id);
      await updateDoc(itemRef, updatedItem);
      setShowModal(false);
    } catch (err) {
      console.error("Error updating item:", err); // Debugging line
      setError("Failed to update item.");
    }
  };

  const deleteItem = async (itemId) => {
    try {
      const itemRef = doc(db, "items", itemId);
      await deleteDoc(itemRef);
    } catch (err) {
      setError("Failed to delete item.");
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const filteredItems = items.filter((item) =>
    item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedItems = [...filteredItems];

  return (
    <Container className="my-5 inventory-container">
      <h2>{t("inventory")}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="mb-3">
        <Col>
          <Button variant="primary" onClick={handleOpenItemFormModal}>
            {t("addItem")}
          </Button>
        </Col>
        <Col>
          <ImportData />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="inventory-col">
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder={t("searchItems")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
          </InputGroup>
          <div className="item-list-container">
            <ItemList
              items={sortedItems.map((item) => ({
                ...item,
                formattedPrice: formatCurrency(item.price),
              }))}
              openModal={openModal}
              deleteItem={deleteItem}
            />
          </div>
        </Col>
      </Row>
      {selectedItem && (
        <ItemModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          addItem={addItem}
          updateItem={updateItem}
        />
      )}
      <ItemModal
        showModal={showItemFormModal}
        setShowModal={setShowItemFormModal}
        selectedItem={null}
        addItem={addItem}
        updateItem={updateItem}
      />
    </Container>
  );
};

export default Inventory;
