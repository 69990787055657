import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore"; // Import onSnapshot and deleteDoc
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Form, Button, Container, Table, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation

const ToolLending = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [tools, setTools] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // Add success state
  const [user] = useAuthState(auth);

  const [toolName, setToolName] = useState("");
  const [borrowerName, setBorrowerName] = useState("");
  const [fee, setFee] = useState(""); // Add fee state

  // Fetch tools from Firestore with real-time updates
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "tools"),
      (snapshot) => {
        setTools(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      },
      (err) => {
        setError(t("failedToFetchTools")); // Use translation
      }
    );

    return () => unsubscribe();
  }, [t]);

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency (e.g., "IQD" for Iraqi Dinar)
    }).format(amount);
  };

  // Lend tool
  const lendTool = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "tools"), {
        toolName,
        borrowerName,
        fee, // Add fee to Firestore document
        date: new Date().toISOString(),
        username: user.email,
      });

      setToolName("");
      setBorrowerName("");
      setFee(""); // Reset fee
    } catch (err) {
      setError(t("failedToLendTool")); // Use translation
    }
  };

  // Delete tool
  const deleteTool = async (id) => {
    if (window.confirm(t("confirmDeleteTool"))) {
      // Add confirmation prompt
      try {
        await deleteDoc(doc(db, "tools", id));
        setSuccess(t("toolDeletedSuccessfully")); // Set success message
      } catch (err) {
        setError(t("failedToDeleteTool")); // Use translation
      }
    }
  };

  return (
    <Container className="my-5">
      <h2>{t("tools")}</h2> {/* Use translation */}
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}{" "}
      {/* Display success message */}
      <Form onSubmit={lendTool}>
        <Form.Group className="mb-3">
          <Form.Label>{t("toolName")}</Form.Label> {/* Use translation */}
          <Form.Control
            type="text"
            value={toolName}
            onChange={(e) => setToolName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("borrowerName")}</Form.Label> {/* Use translation */}
          <Form.Control
            type="text"
            value={borrowerName}
            onChange={(e) => setBorrowerName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("fee")}</Form.Label> {/* Use translation */}
          <Form.Control
            type="number"
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          {t("lendTool")} {/* Use translation */}
        </Button>
      </Form>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>{t("toolName")}</th> {/* Use translation */}
            <th>{t("borrowerName")}</th> {/* Use translation */}
            <th>{t("fee")}</th> {/* Use translation */}
            <th>{t("date")}</th> {/* Use translation */}
            <th>{t("lentBy")}</th> {/* Use translation */}
            <th>{t("actions")}</th> {/* Use translation */}
          </tr>
        </thead>
        <tbody>
          {tools.map((tool) => (
            <tr key={tool.id}>
              <td>{tool.toolName}</td>
              <td>{tool.borrowerName}</td>
              <td>{formatCurrency(tool.fee)}</td>
              <td>{new Date(tool.date).toLocaleDateString()}</td>
              <td>{tool.username}</td>
              <td>
                <Button variant="danger" onClick={() => deleteTool(tool.id)}>
                  {t("delete")} {/* Use translation */}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ToolLending;
