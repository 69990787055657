import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FilterRow, FilterCol } from "../../pages/DashboardStyles";

const ItemFilter = ({ itemsData, selectedItem, setSelectedItem }) => {
  const { t } = useTranslation();

  return (
    <FilterRow>
      <FilterCol>
        <Form.Group controlId="selectedItem">
          <Form.Label>{t("selectItem")}</Form.Label>
          <Form.Control
            as="select"
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
          >
            <option value="">{t("allItems")}</option>
            {itemsData.map((item) => (
              <option key={item.id} value={item.itemName}>
                {item.itemName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </FilterCol>
    </FilterRow>
  );
};

export default ItemFilter;
