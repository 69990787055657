import React, { useState, useEffect, useMemo } from "react";
import { db } from "../firebase";
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { Container, Table, Alert, Accordion, Button, Spinner, Row, Col } from "react-bootstrap"; // Import Row and Col
import { useTranslation } from "react-i18next";
import LoanModal from "../components/LoanModal"; // Import LoanModal component

const Loans = () => {
  const { t } = useTranslation();
  const [salesData, setSalesData] = useState([]);
  const [error, setError] = useState("");
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "sales"),
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSalesData(data);
        setLoading(false); // Set loading to false after fetching data
      },
      (err) => {
        setError("Failed to fetch sales data.");
        setLoading(false); // Set loading to false in case of error
      }
    );

    return () => unsubscribe();
  }, []);

  const formatDate = (date) => {
    if (!date) return "Invalid Date";
    const jsDate = date.toDate ? date.toDate() : new Date(date);
    const day = String(jsDate.getDate()).padStart(2, "0");
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const year = jsDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Format currency based on user's locale (defaults to USD)
  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency (e.g., "IQD" for Iraqi Dinar)
    }).format(amount);
  };

  // Group loans by customerName and calculate total per customer
  const groupedLoans = salesData.reduce((acc, sale) => {
    if (sale.isLoan) {
      const { customerName, totalAmount, paidAmount } = sale;
      if (!acc[customerName]) {
        acc[customerName] = { total: 0, loans: [] };
      }
      acc[customerName].loans.push(sale);
      acc[customerName].total += totalAmount - paidAmount;
    }
    return acc;
  }, {});

  const totalLoansAmount = useMemo(() => {
    return Object.values(groupedLoans).reduce((sum, data) => sum + data.total, 0);
  }, [groupedLoans]);

  const handleClearLoan = (loans) => {
    setSelectedLoan(loans);
    setShowModal(true);
  };

  const handleCloseModal = async (updatedLoans) => {
    if (Array.isArray(updatedLoans)) { // Ensure updatedLoans is an array
      // Update loan documents with the new paidAmount and isLoan status
      for (const loan of updatedLoans) {
        const loanRef = doc(db, "sales", loan.id);
        try {
          await updateDoc(loanRef, {
            paidAmount: loan.paidAmount,
            isLoan: loan.totalAmount - loan.paidAmount > 0, // Update isLoan based on remaining amount
          });
        } catch (err) {
          console.error("Failed to update loan:", err);
        }
      }

      // Update the state with the updated loans
      setSalesData((prevSalesData) =>
        prevSalesData.map((sale) =>
          updatedLoans.some((updatedLoan) => updatedLoan.id === sale.id)
            ? { ...sale, ...updatedLoans.find((updatedLoan) => updatedLoan.id === sale.id) }
            : sale
        )
      );
    }
    setShowModal(false);
    setSelectedLoan(null);
  };

  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h2>{t("loaners")}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {loading ? (
            <Spinner animation="border" />
          ) : salesData.length === 0 ? (
            <h3 className="text-center">{t("emptyList")}</h3>
          ) : (
            <>
              <h4>{t("totalLoans")}: {formatCurrency(totalLoansAmount)}</h4>
              <Accordion>
                {Object.entries(groupedLoans).map(([customerName, data], index) => (
                  <Accordion.Item eventKey={index.toString()} key={customerName}>
                    <Accordion.Header>
                      <div className="d-flex justify-content-between w-100">
                        <span>{customerName} - {t("total")}: {formatCurrency(data.total)}</span>
                        <Button
                          variant="danger"
                          onClick={() => handleClearLoan(data.loans)}
                          className="ms-3"
                          disabled={data.total === 0}
                        >
                          {t("clearLoan")}
                        </Button>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>{t("invoiceNumber")}</th>
                            <th>{t("totalAmount")}</th>
                            <th>{t("paidAmount")}</th>
                            <th>{t("remainingAmount")}</th>
                            <th>{t("date")}</th>
                            <th>{t("addedBy")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.loans.map((loan) => (
                            <tr key={loan.id}>
                              <td>{loan.invoiceNumber}</td>
                              <td>{formatCurrency(loan.totalAmount)}</td>
                              <td>{formatCurrency(loan.paidAmount)}</td>
                              <td>{formatCurrency(loan.totalAmount - loan.paidAmount)}</td>
                              <td>{formatDate(loan.date)}</td>
                              <td>{loan.username}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <h5 className="mt-3">
                        {t("total")}: {formatCurrency(data.total)}
                      </h5>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </>
          )}
          {selectedLoan && (
            <LoanModal
              show={showModal}
              onHide={handleCloseModal}
              loan={selectedLoan}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Loans;
