import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useTranslation } from "react-i18next";
import { FaBell, FaUser } from "react-icons/fa";
import LanguageSwitcher from "./LanguageSwitcher";

const CustomNavbar = () => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          {t("home")}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/" end>
              {t("home")}
            </Nav.Link>
            {user && (
              <>
                <Nav.Link as={NavLink} to="/inventory">
                  {t("inventory")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/sales">
                  {t("sales")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/loans">
                  {t("loans")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/tools">
                  {t("tools")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/invoices">
                  {t("invoices")}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/dashboard">
                  {t("dashboard")}
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {user ? (
              <>
                <NavDropdown title={<FaBell />} id="notifications-dropdown">
                  <NavDropdown.Item href="/notifications">
                    {t("notifications")}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={<FaUser />} id="profile-dropdown">
                  <NavDropdown.Item as={NavLink} to="/profile">
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => auth.signOut()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <NavDropdown title={<FaUser />} id="profile-dropdown">
                <NavDropdown.Item as={NavLink} to="/login">
                  Login
                </NavDropdown.Item>
              </NavDropdown>
            )}
            <span className="mx-2"></span>
            <LanguageSwitcher />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
