import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./PrintableInvoice.css"; // Import the CSS file

const PrintableInvoice = ({
  invoiceNumber,
  customerName,
  selectedItems,
  totalAmount,
  paidAmount,
  remainingAmount,
}) => {
  const { t } = useTranslation();
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const docRef = doc(db, "profile", "companyInfo");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompanyInfo(docSnap.data());
      }
    };
    fetchCompanyInfo();
  }, []);

  return (
    <div className="printable-invoice">
      <h2>{t("invoice")}</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left Section: Contact Information */}
        <div style={{ textAlign: "left" }}>
          <p>
            <strong>{t("companyName")}:</strong> {companyInfo.companyName}
          </p>
          <p>
            <strong>{t("email")}:</strong> {companyInfo.email}
          </p>
          <p>
            <strong>{t("phone")}:</strong> {companyInfo.phone}
          </p>
          <p>
            <strong>{t("address")}:</strong> {companyInfo.address}
          </p>
        </div>

        {/* Right Section: Logo and Invoice Number */}
        <div style={{ textAlign: "right" }}>
          <img
            src="/f_logo.svg" // Updated logo path
            alt={t("companyLogo")}
            style={{ width: "250px", height: "auto" }}
          />
          <p>
            <strong>{t("invoiceNumber")}:</strong> {invoiceNumber}
          </p>
        </div>
      </div>

      {/* Separation Line */}
      <hr style={{ borderTop: "1px solid #000", margin: "20px 0" }} />

      {/* Customer Information */}
      <div style={{ marginBottom: "20px" }}>
        <p>
          <strong>{t("customerName")}:</strong> {customerName}
        </p>
      </div>

      {/* Invoice Table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("itemName")}</th>
            <th>{t("quantitySold")}</th>
            <th>{t("sellCost")}</th>
            <th>{t("discount")}</th>
            <th>{t("total")}</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems.map((item, index) => (
            <tr key={index}>
              <td>{item.itemName}</td>
              <td>{item.quantitySold}</td>
              <td>{item.sellCost}</td>
              <td>{item.discount}</td>
              <td>{((item.sellCost - item.discount) * item.quantitySold).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Total Amount */}
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <p>
          <strong>{t("totalAmount")}:</strong> {totalAmount}
        </p>
        <p>
          <strong>{t("paidAmount")}:</strong> {paidAmount}
        </p>
        <p>
          <strong>{t("remainingAmount")}:</strong> {remainingAmount}
        </p>
      </div>
    </div>
  );
};

export default PrintableInvoice;
