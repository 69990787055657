import React, { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { DashboardContainer, Title } from "./DashboardStyles";
import DateFilter from "../components/dashboard/DateFilter";
import ItemFilter from "../components/dashboard/ItemFilter";
import PieChart from "../components/dashboard/PieChart";
import ItemProfitsTable from "../components/dashboard/ItemProfitsTable";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartTitle,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { t } = useTranslation();
  const [salesData, setSalesData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [itemProfitsAndQuantities, setItemProfitsAndQuantities] = useState({});

  // Fetch sales data from Firestore
  useEffect(() => {
    const salesCollection = collection(db, "sales");
    const unsubscribeSales = onSnapshot(salesCollection, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSalesData(data);
    });

    return () => unsubscribeSales();
  }, []);

  // Fetch items data from Firestore
  useEffect(() => {
    const itemsCollection = collection(db, "items");
    const unsubscribeItems = onSnapshot(itemsCollection, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setItemsData(data);
    });

    return () => unsubscribeItems();
  }, []);

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency (e.g., "IQD" for Iraqi Dinar)
    }).format(amount);
  };

  // Filter data based on date range and selected item
  const filterData = (data) => {
    return data.filter((item) => {
      // Convert Firestore Timestamp to JavaScript Date
      const itemDate = item.date?.toDate
        ? item.date.toDate()
        : new Date(item.date);

      const isWithinDateRange =
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= new Date(endDate));

      const isSelectedItem =
        !selectedItem ||
        (item.items && item.items.some((i) => i.itemName === selectedItem));

      return isWithinDateRange && isSelectedItem;
    });
  };

  useEffect(() => {
    setFilteredSalesData(filterData(salesData));
  }, [salesData, startDate, endDate, selectedItem]);

  useEffect(() => {
    const { totalLoans, totalPaidAmount, totalProfit } =
      calculateTotalLoansAndProfits();
    setPieChartData({
      labels: [t("profit"), t("loans"), t("paid")],
      datasets: [
        {
          data: [totalProfit, totalLoans, totalPaidAmount],
          backgroundColor: [
            "rgba(75, 192, 192, 0.6)",
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
          ],
          borderColor: [
            "rgba(75, 192, 192, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
    setItemProfitsAndQuantities(calculateItemProfitsAndQuantities());
  }, [filteredSalesData, t]);

  // Calculate total profit from sales
  const calculateProfit = () => {
    return filteredSalesData.reduce((sum, sale) => {
      const itemProfit = sale.items
        ? sale.items
            .filter((i) => !selectedItem || i.itemName === selectedItem)
            .reduce(
              (itemSum, i) =>
                itemSum + (i.sellCost - i.realCost) * i.quantitySold,
              0
            )
        : 0;
      return sum + itemProfit;
    }, 0);
  };

  // Calculate total loans
  const calculateLoans = () => {
    return filteredSalesData.reduce((sum, sale) => {
      return sale.isLoan ? sum + (sale.totalAmount - sale.paidAmount) : sum;
    }, 0);
  };

  // Calculate total paid amount from sales
  const calculatePaidAmount = () => {
    return filteredSalesData.reduce((sum, sale) => sum + sale.paidAmount, 0);
  };

  // Calculate total loans and profits
  const calculateTotalLoansAndProfits = () => {
    const totalLoans = calculateLoans().toFixed(2);
    const totalPaidAmount = calculatePaidAmount().toFixed(2);
    const totalProfit = calculateProfit().toFixed(2);
    return { totalLoans, totalPaidAmount, totalProfit };
  };

  // Calculate item profits and quantities sold
  const calculateItemProfitsAndQuantities = () => {
    const itemProfits = {};
    filteredSalesData.forEach((sale) => {
      sale.items.forEach((item) => {
        if (!itemProfits[item.itemName]) {
          itemProfits[item.itemName] = {
            profit: 0,
            quantitySold: 0,
            realCost: 0,
            sellCost: 0,
          };
        }
        itemProfits[item.itemName].profit +=
          (item.sellCost - item.realCost) * item.quantitySold;
        itemProfits[item.itemName].quantitySold += item.quantitySold;
        itemProfits[item.itemName].realCost = item.realCost;
        itemProfits[item.itemName].sellCost = item.sellCost;
      });
    });
    return itemProfits;
  };

  return (
    <DashboardContainer>
      <Title>{t("dashboard")}</Title>

      <DateFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      <ItemFilter
        itemsData={itemsData}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />

      <PieChart pieChartData={pieChartData} />

      <ItemProfitsTable
        itemProfitsAndQuantities={Object.keys(itemProfitsAndQuantities).reduce(
          (acc, key) => {
            acc[key] = {
              ...itemProfitsAndQuantities[key],
              formattedProfit: formatCurrency(
                itemProfitsAndQuantities[key].profit.toFixed(2)
              ),
              formattedRealCost: formatCurrency(
                itemProfitsAndQuantities[key].realCost
              ),
              formattedSellCost: formatCurrency(
                itemProfitsAndQuantities[key].sellCost
              ),
            };
            return acc;
          },
          {}
        )}
        selectedItem={selectedItem}
      />
    </DashboardContainer>
  );
};

export default Dashboard;
