import styled from 'styled-components';

export const DashboardContainer = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

export const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const FilterCol = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 10px;
`;

export const ChartContainer = styled.div`
  margin-bottom: 20px;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;
