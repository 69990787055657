import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FilterRow, FilterCol } from "../../pages/DashboardStyles";

const DateFilter = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const { t } = useTranslation();

  return (
    <FilterRow>
      <FilterCol>
        <Form.Group controlId="startDate">
          <Form.Label>{t("startDate")}</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Form.Group>
      </FilterCol>
      <FilterCol>
        <Form.Group controlId="endDate">
          <Form.Label>{t("endDate")}</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Form.Group>
      </FilterCol>
    </FilterRow>
  );
};

export default DateFilter;
