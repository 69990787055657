import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import * as XLSX from "xlsx";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

const ImportData = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [user] = useAuthState(auth);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(""); // Clear any previous errors
      setSuccess(""); // Clear any previous success messages
    }
  };

  const handleImport = async () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      try {
        const itemsCollection = collection(db, "items");
        for (const item of worksheet) {
          // Ensure required fields are present
          if (
            !item.itemName ||
            !item.quantity ||
            !item.realCost ||
            !item.sellCost
          ) {
            setError("Missing required fields in the file.");
            return;
          }

          // Add the item to Firestore
          await addDoc(itemsCollection, {
            ...item,
            dateAdded: new Date().toISOString(),
            username: user.email,
          });
        }
        setSuccess("Data imported successfully!");
        setError(""); // Clear any errors
        setFile(null); // Reset the file input
      } catch (err) {
        setError("Failed to import data. Please check the file format.");
        console.error("Error importing data:", err);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Import JSON or Excel File</Form.Label>
        <Form.Control
          type="file"
          onChange={handleFileChange}
          accept=".xlsx, .xls, .csv"
        />
      </Form.Group>
      <Button onClick={handleImport} disabled={!file}>
        Import Data
      </Button>
    </div>
  );
};

export default ImportData;
