import React from "react";
import { Container, Card } from "react-bootstrap";
import "./Home.css"; // Import the CSS file for custom styles

const Home = () => {
  return (
    <Container className="mt-4 home-container">
      <Card className="text-center home-card">
        <Card.Body>
          <Card.Title>Welcome to the Shop App</Card.Title>
          <Card.Text>
            Manage your inventory, sales, loans, and tool lending all in one
            place.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Home;
