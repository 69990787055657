import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TableContainer } from "../../pages/DashboardStyles";

const ItemProfitsTable = ({ itemProfitsAndQuantities, selectedItem }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <h3 className="text-center">{t("itemProfitsAndQuantities")}</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("itemName")}</th>
            <th>{t("realCost")}</th>
            <th>{t("sellCost")}</th>
            <th>{t("profit")}</th>
            <th>{t("quantitySold")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(itemProfitsAndQuantities)
            .filter(([itemName]) => !selectedItem || itemName === selectedItem)
            .map(([itemName, data]) => (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>{data.realCost}</td>
                <td>{data.sellCost}</td>
                <td>{data.profit}</td>
                <td>{data.quantitySold}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>{t("total")}</td>
            <td>
              {Object.values(itemProfitsAndQuantities)
                .filter((data) => !selectedItem || data.itemName === selectedItem)
                .reduce((sum, data) => sum + data.realCost * data.quantitySold, 0).toFixed(2)}
            </td>
            <td>
              {Object.values(itemProfitsAndQuantities)
                .filter((data) => !selectedItem || data.itemName === selectedItem)
                .reduce((sum, data) => sum + data.sellCost * data.quantitySold, 0).toFixed(2)}
            </td>
            <td>
              {Object.values(itemProfitsAndQuantities)
                .filter((data) => !selectedItem || data.itemName === selectedItem)
                .reduce((sum, data) => sum + data.profit, 0).toFixed(2)}
            </td>
            <td>
              {Object.values(itemProfitsAndQuantities)
                .filter((data) => !selectedItem || data.itemName === selectedItem)
                .reduce((sum, data) => sum + data.quantitySold, 0).toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </TableContainer>
  );
};

export default ItemProfitsTable;
