import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InvoiceModal from "../components/invoices/InvoiceModal";

const Invoices = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      const querySnapshot = await getDocs(collection(db, "sales"));
      const invoicesData = querySnapshot.docs.map((doc) => {
        let invoice = doc.data();

        // Convert Firestore timestamp to Date string
        if (invoice.date && invoice.date.seconds) {
          invoice.date = new Date(
            invoice.date.seconds * 1000
          ).toLocaleDateString();
        }

        return invoice;
      });
      setInvoices(invoicesData);
    };

    fetchInvoices();
  }, []);

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency (e.g., "IQD" for Iraqi Dinar)
    }).format(amount);
  };

  const handleShowModal = (invoice) => {
    setSelectedInvoice(invoice);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">{t("invoices")}</h1>
      <Accordion>
        {invoices.map((invoice, index) => (
          <Accordion.Item eventKey={String(index)} key={invoice.invoiceNumber}>
            <Accordion.Header>
              {invoice.invoiceNumber} - {invoice.customerName}
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-12 col-md-6">
                  <p>
                    <strong>{t("date")}:</strong> {invoice.date || "N/A"}
                  </p>
                  <p>
                    <strong>{t("totalAmount")}:</strong>{" "}
                    {formatCurrency(invoice.totalAmount)}
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <strong>{t("paidAmount")}:</strong>{" "}
                    {formatCurrency(invoice.paidAmount)}
                  </p>
                  <p>
                    <strong>{t("remainingAmount")}:</strong>{" "}
                    {formatCurrency(invoice.totalAmount - invoice.paidAmount)}
                  </p>
                  <p>
                    <strong>{t("addedBy")}:</strong> {invoice.username}
                  </p>
                </div>
              </div>
              <Button
                variant="primary"
                onClick={() => handleShowModal(invoice)}
              >
                {t("viewDetails")}
              </Button>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {selectedInvoice && (
        <InvoiceModal
          show={showModal}
          handleClose={handleCloseModal}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
};

export default Invoices;
