import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth); // Get the current user and loading state

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner while checking authentication
  }

  if (!user) {
    return <Navigate to="/login" />; // Redirect to login if the user is not authenticated
  }

  // Clone the children and pass the user as a prop
  return React.cloneElement(children, { user });
};

export default PrivateRoute;
