import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaPlus,
  FaMinus,
  FaTag,
  FaDollarSign,
  FaPercentage,
  FaBoxes,
  FaIndustry,
  FaBarcode,
  FaMoneyBillWave,
  FaExchangeAlt,
  FaUser,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhone,
} from "react-icons/fa";

const ItemForm = ({ addItem, item }) => {
  const { t } = useTranslation();
  const [itemName, setItemName] = useState(item ? item.itemName : "");
  const [realCost, setRealCost] = useState(item ? item.realCost : "");
  const [sellCost, setSellCost] = useState(item ? item.sellCost : "");
  const [discount, setDiscount] = useState(item ? item.discount : "");
  const [quantity, setQuantity] = useState(item ? item.quantity : "");
  const [currencyName, setCurrencyName] = useState(item ? item.currencyName : "USD");
  const [exchangeRate, setExchangeRate] = useState(item ? item.exchangeRate : 1449);
  const [unit, setUnit] = useState(item ? item.unit : "pcs");
  const [manufacturer, setManufacturer] = useState(item ? item.manufacturer : "");
  const [model, setModel] = useState(item ? item.model : "");
  const [color, setColor] = useState(item ? item.color : "");
  const [weight, setWeight] = useState(item ? item.weight : "");
  const [size, setSize] = useState(item ? item.size : "");
  const [height, setHeight] = useState(item ? item.height : "");
  const [width, setWidth] = useState(item ? item.width : "");
  const [length, setLength] = useState(item ? item.length : "");
  const [material, setMaterial] = useState(item ? item.material : "");
  const [supplierName, setSupplierName] = useState(item ? item.supplierName : "");
  const [supplierEmail, setSupplierEmail] = useState(item ? item.supplierEmail : "");
  const [supplierAddress, setSupplierAddress] = useState(item ? item.supplierAddress : "");
  const [supplierPhone, setSupplierPhone] = useState(item ? item.supplierPhone : "");
  const [showItemProperties, setShowItemProperties] = useState(false);
  const [showSupplierInfo, setShowSupplierInfo] = useState(false);

  const handleAddItemProperties = () =>
    setShowItemProperties(!showItemProperties);
  const handleAddSupplierInfo = () => setShowSupplierInfo(!showSupplierInfo);

  const handleSubmit = (e) => {
    e.preventDefault();
    addItem({
      id: item ? item.id : undefined,
      itemName,
      realCost: parseFloat(realCost),
      sellCost: parseFloat(sellCost),
      discount: parseFloat(discount),
      quantity: parseInt(quantity),
      currencyName,
      exchangeRate: parseFloat(exchangeRate),
      unit,
      manufacturer,
      model,
      color: color || "",
      weight: weight || "",
      size: size || "",
      height: height || "",
      width: width || "",
      length: length || "",
      material: material || "",
      supplierName: supplierName || "",
      supplierEmail: supplierEmail || "",
      supplierAddress: supplierAddress || "",
      supplierPhone: supplierPhone || "",
    });
    setItemName("");
    setRealCost("");
    setSellCost("");
    setDiscount("");
    setQuantity("");
    setCurrencyName("USD");
    setExchangeRate(1);
    setUnit("pcs");
    setManufacturer("");
    setModel("");
    setColor("");
    setWeight("");
    setSize("");
    setHeight("");
    setWidth("");
    setLength("");
    setMaterial("");
    setSupplierName("");
    setSupplierEmail("");
    setSupplierAddress("");
    setSupplierPhone("");
  };

  const units = ["pcs", "box", "drum", "kg", "litre", "pack", "carton","m","cm","mm","ft","inch","ml","g","mg","watt","kw"];

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaTag /> {t("itemName")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t("itemName")}
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaDollarSign /> {t("realCost")}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t("realCost")}
          value={realCost}
          onChange={(e) => setRealCost(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaDollarSign /> {t("sellCost")}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t("sellCost")}
          value={sellCost}
          onChange={(e) => setSellCost(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaPercentage /> {t("discount")}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t("discount")}
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaBoxes /> {t("quantity")}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t("quantity")}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("unit")}</Form.Label>
        <Form.Select
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
          required
        >
          {units.map((unitOption) => (
            <option key={unitOption} value={unitOption}>
              {t(unitOption)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaIndustry /> {t("manufacturer")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t("manufacturer")}
          value={manufacturer}
          onChange={(e) => setManufacturer(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaBarcode /> {t("model")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t("model")}
          value={model}
          onChange={(e) => setModel(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaMoneyBillWave /> {t("currencyName")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t("currencyName")}
          value={currencyName}
          onChange={(e) => setCurrencyName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FaExchangeAlt /> {t("exchangeRate")}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t("exchangeRate")}
          value={exchangeRate}
          onChange={(e) => setExchangeRate(e.target.value)}
          required
        />
      </Form.Group>
      <fieldset className="border p-2 mb-3">
        <legend className="w-auto">{t("addItemProperties")}</legend>
        <Button
          type="button"
          onClick={handleAddItemProperties}
          className="mb-3"
          variant="link"
        >
          {showItemProperties ? <FaMinus /> : <FaPlus />}{" "}
          {t("addItemProperties")}
        </Button>
        {showItemProperties && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>{t("color")}</Form.Label>
              <Form.Control
                type="text"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("weight")}</Form.Label>
              <Form.Control
                type="text"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("size")}</Form.Label>
              <Form.Control
                type="text"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("height")}</Form.Label>
              <Form.Control
                type="text"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("width")}</Form.Label>
              <Form.Control
                type="text"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("length")}</Form.Label>
              <Form.Control
                type="text"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("material")}</Form.Label>
              <Form.Control
                type="text"
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
              />
            </Form.Group>
          </>
        )}
      </fieldset>
      <fieldset className="border p-2 mb-3">
        <legend className="w-auto">{t("addSupplierInfo")}</legend>
        <Button
          type="button"
          onClick={handleAddSupplierInfo}
          className="mb-3"
          variant="link"
        >
          {showSupplierInfo ? <FaMinus /> : <FaPlus />} {t("addSupplierInfo")}
        </Button>
        {showSupplierInfo && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaUser /> {t("supplierName")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("supplierName")}
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaEnvelope /> {t("supplierEmail")}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={t("supplierEmail")}
                value={supplierEmail}
                onChange={(e) => setSupplierEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaMapMarkerAlt /> {t("supplierAddress")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("supplierAddress")}
                value={supplierAddress}
                onChange={(e) => setSupplierAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaPhone /> {t("supplierPhone")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("supplierPhone")}
                value={supplierPhone}
                onChange={(e) => setSupplierPhone(e.target.value)}
              />
            </Form.Group>
          </>
        )}
      </fieldset>
      <Button variant="primary" type="submit">
        <FaPlus /> {item ? t("saveChanges") : t("addItem")}
      </Button>
    </Form>
  );
};

export default ItemForm;
