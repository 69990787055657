import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
  query,
  getDocs,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  Form,
  Button,
  Container,
  Table,
  Alert,
  ListGroup,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SaleModal from "../components/sales/SaleModal";

const Sales = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [user] = useAuthState(auth);
  const [quantitySoldMap, setQuantitySoldMap] = useState({});

  const [customerName, setCustomerName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invalidRows, setInvalidRows] = useState({});
  const [suggestedCustomers, setSuggestedCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);

  useEffect(() => {
    const itemsCollection = collection(db, "items");

    const unsubscribe = onSnapshot(itemsCollection, (querySnapshot) => {
      const itemsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setItems(itemsData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      const loansRef = collection(db, "loans");
      const salesRef = collection(db, "sales");

      const loansQuery = query(loansRef);
      const loansSnapshot = await getDocs(loansQuery);
      const loansCustomers = loansSnapshot.docs.map(
        (doc) => doc.data().customerName
      );

      const salesQuery = query(salesRef);
      const salesSnapshot = await getDocs(salesQuery);
      const salesCustomers = salesSnapshot.docs.map(
        (doc) => doc.data().customerName
      );

      const uniqueCustomers = [
        ...new Set([...loansCustomers, ...salesCustomers]),
      ];
      setAllCustomers(uniqueCustomers);
    };

    fetchCustomers();
  }, []);

  const handleCustomerNameChange = (e) => {
    const name = e.target.value;
    setCustomerName(name);
    console.log(allCustomers);
    if (name.length > 1) {
      const filteredCustomers = allCustomers.filter((customer) =>
        customer
          .normalize("NFKC")
          .toLowerCase()
          .includes(name.normalize("NFKC").toLowerCase())
      );
      setSuggestedCustomers(filteredCustomers);
    } else {
      setSuggestedCustomers([]);
    }
  };

  const selectCustomer = (customer) => {
    setCustomerName(customer);
    setSuggestedCustomers([]);
  };

  const filteredItems = items.filter((item) =>
    item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const addToInvoice = (item) => {
    const quantitySold = quantitySoldMap[item.id] || 1;

    if (
      isNaN(quantitySold) ||
      quantitySold < 1 ||
      quantitySold > item.quantity
    ) {
      setInvalidRows((prev) => ({
        ...prev,
        [item.id]: "Invalid quantity range",
      }));
      return;
    }

    setInvalidRows((prev) => ({ ...prev, [item.id]: "" }));

    const existingItemIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    if (existingItemIndex !== -1) {
      const updatedItems = [...selectedItems];
      updatedItems[existingItemIndex].quantitySold += quantitySold;

      setSelectedItems(updatedItems);
    } else {
      setSelectedItems([...selectedItems, { ...item, quantitySold }]);
    }

    setItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? {
              ...prevItem,
              quantity: Math.max(prevItem.quantity - quantitySold, 0),
            }
          : prevItem
      )
    );

    setQuantitySoldMap((prev) => ({ ...prev, [item.id]: 1 }));
  };

  const removeFromInvoice = (index) => {
    const item = selectedItems[index];

    setItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? { ...prevItem, quantity: prevItem.quantity + item.quantitySold }
          : prevItem
      )
    );

    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const updateSelectedItem = (index, field, value) => {
    if (isNaN(value) || value < 1) return;

    const updatedItems = [...selectedItems];
    const item = updatedItems[index];
    const originalQuantity = item.quantitySold;
    const newQuantity = Number(value);

    if (newQuantity < originalQuantity) {
      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.id === item.id
            ? {
                ...prevItem,
                quantity: prevItem.quantity + (originalQuantity - newQuantity),
              }
            : prevItem
        )
      );
    } else if (newQuantity > originalQuantity) {
      const totalSoldQuantity =
        originalQuantity + (newQuantity - originalQuantity);

      if (totalSoldQuantity > item.quantity) {
        return;
      }

      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.id === item.id
            ? {
                ...prevItem,
                quantity: Math.max(
                  prevItem.quantity - (newQuantity - originalQuantity),
                  0
                ),
              }
            : prevItem
        )
      );
    }

    updatedItems[index][field] = newQuantity;
    setSelectedItems(updatedItems);
  };

  const generateInvoiceNumber = async () => {
    return `INV-${Date.now()}`;
  };

  const openInvoiceModal = async (e) => {
    e.preventDefault();
    try {
      const invoiceNum = await generateInvoiceNumber();
      setInvoiceNumber(invoiceNum);
      setShowInvoiceModal(true);
    } catch (err) {
      setError(t("failedToGenerateInvoiceNumber"));
    }
  };

  const saveInvoice = async () => {
    try {
      const totalAmount = selectedItems.reduce(
        (sum, item) =>
          sum + (item.sellCost - item.discount) * item.quantitySold,
        0
      );

      const isLoan = totalAmount > amountPaid;

      const saleRef = await addDoc(collection(db, "sales"), {
        invoiceNumber,
        customerName,
        items: selectedItems,
        totalAmount,
        paidAmount: amountPaid,
        date: serverTimestamp(),
        username: user.email,
        isLoan,
      });

      if (isLoan) {
        await setDoc(doc(db, "loans", saleRef.id), {
          invoiceNumber,
          customerName,
          items: selectedItems,
          totalAmount,
          paidAmount: amountPaid,
          date: serverTimestamp(),
          username: user.email,
        });
      }

      for (const item of selectedItems) {
        const itemRef = doc(db, "items", item.id);
        await updateDoc(itemRef, {
          quantity: item.quantity - item.quantitySold,
        });
      }

      setCustomerName("");
      setInvoiceNumber("");
      setSelectedItems([]);
      setAmountPaid(0);
      setShowInvoiceModal(false);
    } catch (err) {
      setError(t("failedToSaveInvoice"));
    }
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return t("invalidAmount");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your preferred currency
    }).format(amount);
  };

  const totalRealCost = selectedItems.reduce(
    (sum, item) => sum + item.realCost * item.quantitySold,
    0
  ).toFixed(2);
  const totalSellCost = selectedItems.reduce(
    (sum, item) => sum + item.sellCost * item.quantitySold,
    0
  ).toFixed(2);

  const totalProfit = (totalSellCost - totalRealCost).toFixed(2);

  const totalItems = selectedItems.reduce(
    (sum, item) => sum + item.quantitySold,
    0
  );

  return (
    <Container className="w-100 px-3">
      <h2 className="text-center">{t("sales")}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={openInvoiceModal}>
        <Form.Group className="mb-3">
          <Form.Label>{t("customerName")}</Form.Label>
          <Form.Control
            type="text"
            value={customerName}
            onChange={handleCustomerNameChange}
            placeholder={t("enterCustomerName")}
            required
          />
          {suggestedCustomers.length > 0 && (
            <ListGroup className="mt-2">
              {suggestedCustomers.map((customer, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => selectCustomer(customer)}
                >
                  {customer}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("paidAmount")}</Form.Label>
          <Form.Control
            type="number"
            value={amountPaid}
            onChange={(e) => setAmountPaid(parseFloat(e.target.value))}
            placeholder={t("paidAmount")}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100">
          {t("generateInvoice")}
        </Button>
      </Form>

      <InputGroup className="mb-3 mt-4">
        <FormControl
          placeholder={t("searchItems")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      <Row>
        <Col md={6} className="mb-4">
          <h3 className="text-center">{t("availableItems")}</h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("itemName")}</th>
                <th>{t("realCost")}</th>
                <th>{t("sellCost")}</th>
                <th>{t("discount")}</th>
                <th>{t("quantityAvailable")}</th>
                <th>{t("quantitySold")}</th>
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item) => (
                <tr
                  key={item.id}
                  className={invalidRows[item.id] ? "table-danger" : ""}
                >
                  <td>{item.itemName}</td>
                  <td>{item.realCost}</td>
                  <td>{item.sellCost}</td>
                  <td>{item.discount}</td>
                  <td>{item.quantity || 0}</td>
                  <td>
                    <Form.Control
                      type="number"
                      min="1"
                      max={item.quantity || 1}
                      value={quantitySoldMap[item.id] || 1}
                      onChange={(e) => {
                        const quantitySold = parseFloat(e.target.value);
                        setQuantitySoldMap((prev) => ({
                          ...prev,
                          [item.id]: quantitySold,
                        }));
                      }}
                    />
                    {invalidRows[item.id] && (
                      <small className="text-danger">
                        {invalidRows[item.id]}
                      </small>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => addToInvoice(item)}
                      disabled={!item.quantity || item.quantity < 1}
                    >
                      {t("addToInvoice")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>

        <Col md={6}>
          <h3 className="text-center">{t("selectedItems")}</h3>

          <div className="mb-3 text-center">
            <strong>{t("totalRealCost")}: </strong>
            {formatCurrency(totalRealCost)}
            <br />
            <strong>{t("totalSellCost")}: </strong>
            {formatCurrency(totalSellCost)}
            <br />
            <strong>{t("totalItems")}: </strong>
            {totalItems}
            <br />
            <strong>{t("totalProfit")}: </strong>
            {formatCurrency(totalProfit)}
            <br />
            <strong>{t("paidAmount")}: </strong>
            {formatCurrency(amountPaid)}
            <br />
            <strong>{t("remainingAmount")}: </strong>
            {formatCurrency(totalSellCost - amountPaid)}
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("itemName")}</th>
                <th>{t("sellCost")}</th>
                <th>{t("discount")}</th>
                <th>{t("quantitySold")}</th>
                <th>{t("total")}</th>
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {selectedItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.itemName}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.sellCost}
                      onChange={(e) =>
                        updateSelectedItem(
                          index,
                          "sellCost",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.discount}
                      onChange={(e) =>
                        updateSelectedItem(
                          index,
                          "discount",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.quantitySold}
                      onChange={(e) =>
                        updateSelectedItem(
                          index,
                          "quantitySold",
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </td>
                  <td>{formatCurrency((item.sellCost - item.discount) * item.quantitySold)}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => removeFromInvoice(index)}
                    >
                      {t("remove")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <SaleModal
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        invoiceNumber={invoiceNumber}
        customerName={customerName}
        selectedItems={selectedItems}
        amountPaid={amountPaid}
        saveInvoice={saveInvoice}
      />
    </Container>
  );
};

export default Sales;
