import React, { useState, useMemo, useRef, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const ItemList = ({ items, openModal, deleteItem }) => {
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({
    key: "itemName",
    direction: "ascending",
  });
  const [heldItemId, setHeldItemId] = useState(null);
  const holdTimeout = useRef(null);
  const holdTriggered = useRef(false);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return <FaSort />;
    }
    return sortConfig.direction === "ascending" ? <FaSortUp /> : <FaSortDown />;
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setHeldItemId(null);
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMouseDown = (itemId, event) => {
    event.stopPropagation();
    holdTriggered.current = false;
    holdTimeout.current = setTimeout(() => {
      setHeldItemId(itemId);
      holdTriggered.current = true;
    }, 1000); // 1 second hold time
  };

  const handleMouseUp = (event) => {
    event.stopPropagation();
    clearTimeout(holdTimeout.current);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{ width: "5%" }}>#</th>
          <th onClick={() => requestSort("itemName")}>
            {t("itemName")} {getSortIcon("itemName")}
          </th>
          <th onClick={() => requestSort("quantity")}>
            {t("quantityAvailable")} {getSortIcon("quantity")}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((item, index) => (
          <tr
            key={item.id}
            onMouseDown={(e) => handleMouseDown(item.id, e)}
            onMouseUp={handleMouseUp}
            onClick={(e) => {
              e.stopPropagation();
              if (!holdTriggered.current) {
                openModal(item);
              }
            }}
          >
            <td>{index + 1}</td>
            <td>
              {heldItemId === item.id && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteItem(item.id);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Delete
                </Button>
              )}
              <span style={{ marginLeft: heldItemId === item.id ? "10px" : "0" }}>
                {item.itemName}
              </span>
            </td>
            <td>{item.quantity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ItemList;
